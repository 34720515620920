import React from 'react';
import './banner.scss';

const HMBanner = () => {
  return (
    <div className="banner__container">
      <img src="./footer/banner.png" alt="banner" />
    </div>
  );
};

export default HMBanner;
