import React, { useState } from 'react';
import './giftcard.scss';
import { useSwipeable } from 'react-swipeable';

const GiftCard = () => {
  const giftItem = [
    {
      id: 0,
      imagePath: './gift/gift1.png',
      width: '100%',
      position: 'relative',
      itemNumber: '0',
      top: '0'
    },
    {
      id: 1,
      imagePath: './gift/gift3.png',
      width: '100%',
      position: 'relative',
      itemNumber: '1',
      top: '0'
    },
    {
      id: 2,
      imagePath: './gift/gift3.png',
      width: '100%',
      position: 'relative',
      itemNumber: '2',
      top: '0'
    },
    {
      id: 3,
      imagePath: './gift/gift1.png',
      width: '100%',
      position: 'relative',
      itemNumber: '3',
      top: '0'
    }
  ];

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = giftItem.length - giftItem.length / 2;
    } else if (newIndex >= giftItem.length / 2) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const setItem = (id: number) => {
    console.log(id);
    setActiveIndex(id - 1);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="giftcard__container">
      <div {...handlers} className="carousel">
        <div className="inner" style={{ transform: `translate(-${activeIndex * 22.5}%)` }}>
          {giftItem.map(({ imagePath, id, width }) => {
            return (
              <div
                className={activeIndex + 1 === id ? 'scaleClass' : 'descendClass'}
                key={id}
                onClick={() => setItem(id)}
                style={{
                  maxWidth: width,
                  position: 'relative'
                }}>
                <img
                  className={id <= activeIndex + 2 && id >= activeIndex ? '' : 'hide'}
                  src={imagePath}
                  alt="FTHighlights"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="description">
        <h2>Gift an experience</h2>
        <p>With FoodTolls</p>
        <button>Know More</button>
      </div>
    </div>
  );
};

export default GiftCard;
