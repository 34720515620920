import React from 'react';
import './recent-search.scss';

const RecentSearch = () => {
  const recentSearch = [
    { id: 1, searchText: 'Holiday Rentals in Bengaluru', subText: '', type: 'search' },
    { id: 2, searchText: 'Bengaluru', subText: 'Bengalore District', type: 'location' },
    { id: 3, searchText: 'Things to do in Bengaluru', subText: '', type: 'search' },
    { id: 4, searchText: 'Hotels in Bengaluru', subText: '', type: 'search' },
    { id: 5, searchText: 'Restaurants in Chennai', subText: '', type: 'search' }
  ];

  const iconRender = (type: string) => {
    switch (type) {
      case 'location':
        return <img src="./icons/location_icon.png" alt="loc" style={{ maxWidth: '20px' }} />;
      case 'search':
        return <img src="./icons/search_icon.png" alt="search" style={{ maxWidth: '20px' }} />;
      default:
    }
  };

  return (
    <div className="recent_container">
      <h1>Your recent searches</h1>
      <div className="search__tab__container">
        {recentSearch.map(({ id, searchText, subText, type }) => {
          return (
            <div key={id} className="search__content">
              {iconRender(type)}
              <div className="search__text">
                <p className="mainText">{searchText}</p>
                {subText !== '' ? <p className="subText">{subText}</p> : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecentSearch;
