import React from 'react';
import './whyFoodTolls.scss';
const WhyFoodTolls = () => {
  const content = {
    path: './whyft/backgroung_why.svg',
    header: 'Planned Trip',
    heading: 'Why Choose Foodtolls?',
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
    uniqueness: ['Get a personalized trip', 'Customize it', 'Book your food']
  };
  return (
    <div className="main_conatiner" style={{ backgroundImage: `url(${content.path})` }}>
      <div className="why_content">
        <div className="description">
          <p className="header">{content.header} </p>
          <h2 className="heading">{content.heading}</h2>
          <p className="content"> {content.content}</p>
        </div>
        <div className="uniqueness">
          {content.uniqueness.map((unique) => (
            <div key={unique} className="unique_indv">
              <img src="./whyft/tag.png" alt="tag" />
              <p className="text_desc">{unique}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyFoodTolls;
