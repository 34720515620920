import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './commingsoon.scss'

const CommingSoon = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        setIsModalOpen(true)
    }, [])

    const handleOk = () => {
        setIsModalOpen(false);
        navigate('/')
    };

    const handleCancel = () => {
        navigate('/')
        setIsModalOpen(false);
    };
    return (<>
        <Modal title="Comming Soon" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div>
                <label >Your Travel Companion is growing rapidly to assist you!! </label>
            </div>
        </Modal>
    </>)

}

export default CommingSoon;