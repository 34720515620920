import React, { useState } from 'react';
import './navbar.scss';
import { Link, useNavigate } from 'react-router-dom';
// import {Icon} from '@iconify/react';
import GlobalSearch from '../globalSearch/globalsearch';
import LoginSignup from '../loginsignup/loginsignup';
import Logo from '../Logo/logo';

const NavBar = () => {
  const navItem = [
    { id: 1, name: 'Plan a trip', path: '/' },
    { id: 2, name: 'Recommend a trip', path: '/commingsoon' },
    { id: 3, name: 'Food', path: '/food' },
    { id: 4, name: 'Stay', path: '/commingsoon' },
    { id: 5, name: 'Attractions', path: '/commingsoon' }
  ];
  const navigate = useNavigate()
  const [showloginOptions, setloginOptions] = useState(false)
  const [showLoginPopUp, setShowLoginPopUp] = useState(false)
  return (
    <div>
      <div className="nav__Container">
        <Logo />
        <div className="nav__List">
          <div className="nav__itemSearch">
            <div className="nav__Item">
              {navItem.map(({ id, path, name }) => (
                <Link key={id} to={path} className="item">
                  {name}
                  <div className="item__UnderLine"></div>
                </Link>
              ))}
              <img className="arrow"
                src="./icons/rightMiniArrow.png"
                alt="rightArrow"
                onClick={() => navigate('/commingsoon')}
              />
            </div>

            <GlobalSearch />
          </div>
          <div className="signIn_Out" onMouseOver={() => setloginOptions(true)} onMouseOut={() => setloginOptions(false)} onClick={() => setloginOptions(!showloginOptions)}>
            <img src="loginIcon.png" alt="loginIcon" />
            {showloginOptions &&
              <div className="signIn_OUT_Dropdown">
                <p>Welcome</p>
                <p>To access account and manage bookings/orders</p>
                <button onClick={() => setShowLoginPopUp(true)}>LOGIN / SIGNUP</button>
              </div>}
          </div>
        </div>
      </div>
      {showLoginPopUp && <LoginSignup />}
    </div >
  );
};

export default NavBar;
