import React from "react";
import './addRemove.scss'

const AddRemoveButton = ({ Count, itemCountAdd, itemCountSub, imgClass }: any) => {
    return (<div className={imgClass}>
        <img src="/icons/minus.png"
            className="subIcon"
            onClick={itemCountSub}
        />
        <span id="animate">{Count}</span>
        <img src="/icons/plus.png" className="subIcon" onClick={itemCountAdd} />
    </div>)
}

export default AddRemoveButton