import React from 'react';
import './locationdropdown.scss';
import { useSelector } from 'react-redux';

interface dropDownType {
  dropDown: { id: number; place: string }[];
}

const LocationDropdown: React.FC<dropDownType> = ({ dropDown }) => {
  const cityChange = (e: any) => {
    console.log(e.target.value)
  }
  const location = useSelector((state: any) => state.gmap.currentLocation.city ? state.gmap.currentLocation?.city : state.gmap.currentLocation)
  return (
    <select className="dropDown" defaultValue={location} onChange={(event) => cityChange(event)}>
      <option >{location}</option>
      {dropDown.map(({ id, place }) => {
        return (
          <option key={id} value={id}>
            {place}
          </option>
        );
      })}
    </select>
  );
};

export default LocationDropdown;
