/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState: {
        restaurant: [{
            id: 1, name: 'Kannur Food Point', type: 'veg', subDes: 'Kerala, Chinese', rating: '4.0', costForTwo: '500', discount: '50% of on all orders', imgPath: '/hotels/kannurFP.png', address: 'SG palaya, S.G. Palya'
            , foodMenu: {
                types: ['Recommend', 'Ice-Creams', "Mains", 'Rice', 'Chinese', 'Beverages'],
                menu: [{ id: 1, veg: false, imgPath: '/menuItems/chickenMeal.png', name: 'Chicken Meal', price: '160', desc: '| Serve 1 | Medium spicy | Chicken curry + dal + 2 roti + rice + sweet. Salad, pickle and spoon sent on request only. Customization of components may not be possible as the cooking happens in bulk. No Jain Food available.', type: 'Recommend', recommended: true },
                { id: 2, veg: false, imgPath: '/menuItems/eggNoddles.png', name: 'Egg Noodles', price: '100', desc: 'Hakka Noodles cooked with egg, veggies spices and sauces. NO MSG/AJINOMOTTO USED', type: 'Recommend', recommended: true },
                { id: 3, veg: true, imgPath: '', name: 'Black Current Ice Cream', price: '89', desc: 'Black Current Ice Cream scoop served in a cup', type: 'Ice-Creams', recommended: false },
                { id: 4, veg: true, imgPath: '', name: 'Cookies And Cream Ice Creamcream', price: '89', desc: 'Cookies And Cream Ice Cream scoop served in a cup', type: 'Ice-Creams', recommended: false },
                { id: 5, veg: false, imgPath: '/menuItems/eggbhurji.png', name: 'Egg Bhurji', price: '100', desc: '| Serve 1 | Medium spicy | Bhurji prepared with 2 eggs, onion, tomatoes, Indian spices and green chili. Low on oil and customizable on spices. No Jain Food available. Serves one light eater.', type: 'Mains', recommended: false },
                { id: 6, veg: true, imgPath: '/menuItems/paneerMasala.png', name: 'Paneer Masala', price: '125', desc: 'Paneer curry cooked in onion and tomatoes based gravy with garlic chunks and indian spices. Perfect match for both rice or roti or paratha.', type: 'Mains', recommended: false },
                { id: 7, veg: false, imgPath: '/menuItems/eggrice.png', name: 'Egg Rice', price: '85', desc: '| Serve 1 | Medium spicy | 2 eggs scrambled with, green chilies, salt and pepper tossed with quality rice. Served with tomato sauce sachet.', type: 'Rice', recommended: false },
                { id: 8, veg: true, imgPath: '/menuItems/jeeraRice.png', name: 'Jeera Rice', price: '70', desc: '| Serve 1 | Rice prepared with tempering of jeera, green chillies, coriander and branded cow ghee.', type: 'Rice', recommended: false },
                { id: 9, veg: false, imgPath: '/menuItems/chickenFriedRice.png', name: 'Chicken Fried Rice', price: '110', desc: '| Serve 1 | Medium spicy | Rice tossed with chicken and egg. NO MSG/AJINOMOTO USED.', type: 'Chinese', recommended: false },
                { id: 10, veg: true, imgPath: '/menuItems/vegNoodles.png', name: 'Veg Noodles', price: '90', desc: '| Serve 1 | Medium spicy | Hakka Noodles made with veggies and sauces.', type: 'Chinese', recommended: false },
                { id: 11, veg: true, imgPath: '/menuItems/buttermilk.png', name: 'Buttermilk', price: '45', desc: 'Buttermilk prepared with curd and special in house butter milk masala added to enhance the taste.', type: 'Beverages', recommended: false },
                ]
            }
        },
        {
            id: 2, name: 'Empire', type: 'non-veg', subDes: 'North Indian, Kebabs, Biriyani', rating: '4.2', costForTwo: '450', discount: '10% of | Use TryNew', imgPath: '/hotels/empire.png', address: '100 Ft Ring Road, Banashankari',
            foodMenu: {
                types: ['Recommend', 'Ice-Creams', "Mains", 'Rice', 'Chinese', 'Beverages'],
                menu: [{ id: 1, veg: false, imgPath: '/menuItems/chickenMeal.png', name: 'Chicken Meal', price: '160', desc: '| Serve 1 | Medium spicy | Chicken curry + dal + 2 roti + rice + sweet. Salad, pickle and spoon sent on request only. Customization of components may not be possible as the cooking happens in bulk. No Jain Food available.', type: 'Recommend', recommended: true },
                { id: 2, veg: false, imgPath: '/menuItems/eggNoddles.png', name: 'Egg Noodles', price: '100', desc: 'Hakka Noodles cooked with egg, veggies spices and sauces. NO MSG/AJINOMOTTO USED', type: 'Recommend', recommended: true },
                { id: 3, veg: true, imgPath: '', name: 'Black Current Ice Cream', price: '89', desc: 'Black Current Ice Cream scoop served in a cup', type: 'Ice-Creams', recommended: false },
                { id: 4, veg: true, imgPath: '', name: 'Cookies And Cream Ice Creamcream', price: '89', desc: 'Cookies And Cream Ice Cream scoop served in a cup', type: 'Ice-Creams', recommended: false },
                { id: 5, veg: false, imgPath: '/menuItems/eggbhurji.png', name: 'Egg Bhurji', price: '100', desc: '| Serve 1 | Medium spicy | Bhurji prepared with 2 eggs, onion, tomatoes, Indian spices and green chili. Low on oil and customizable on spices. No Jain Food available. Serves one light eater.', type: 'Mains', recommended: false },
                { id: 6, veg: true, imgPath: '/menuItems/paneerMasala.png', name: 'Paneer Masala', price: '125', desc: 'Paneer curry cooked in onion and tomatoes based gravy with garlic chunks and indian spices. Perfect match for both rice or roti or paratha.', type: 'Mains', recommended: false },
                { id: 7, veg: false, imgPath: '/menuItems/eggrice.png', name: 'Egg Rice', price: '85', desc: '| Serve 1 | Medium spicy | 2 eggs scrambled with, green chilies, salt and pepper tossed with quality rice. Served with tomato sauce sachet.', type: 'Rice', recommended: false },
                { id: 8, veg: true, imgPath: '/menuItems/jeeraRice.png', name: 'Jeera Rice', price: '70', desc: '| Serve 1 | Rice prepared with tempering of jeera, green chillies, coriander and branded cow ghee.', type: 'Rice', recommended: false },
                { id: 9, veg: false, imgPath: '/menuItems/chickenFriedRice.png', name: 'Chicken Fried Rice', price: '110', desc: '| Serve 1 | Medium spicy | Rice tossed with chicken and egg. NO MSG/AJINOMOTO USED.', type: 'Chinese', recommended: false },
                { id: 10, veg: true, imgPath: '/menuItems/vegNoodles.png', name: 'Veg Noodles', price: '90', desc: '| Serve 1 | Medium spicy | Hakka Noodles made with veggies and sauces.', type: 'Chinese', recommended: false },
                { id: 11, veg: true, imgPath: '/menuItems/buttermilk.png', name: 'Buttermilk', price: '45', desc: 'Buttermilk prepared with curd and special in house butter milk masala added to enhance the taste.', type: 'Beverages', recommended: false },
                ]
            }
        },
        {
            id: 3, name: 'Ayodhya Upachar', type: 'veg', subDes: 'Chinese, North Indian, Desserts, South Indian, Chaat', rating: '4.4', costForTwo: '200', discount: '20% of | Use TryNew', imgPath: '/hotels/ayodhya.png', address: 'Kathriguppe, Banashankari',
        },
        { id: 4, name: 'Kabab Magic', type: 'non-veg', subDes: 'North Indian, Chinese, Biriyani, Tandoor', rating: '4.2', costForTwo: '250', discount: 'Free Delivery', imgPath: '/hotels/kababMagic.png', address: 'Yeddur lake,Banashankari' },
        { id: 5, name: 'Meghana Foods', type: 'non-veg', subDes: 'Biriyani, Andhra, South Indian, Chinese,North Indian, SeaFood', rating: '4.4', costForTwo: '500', discount: 'Free Delivery', imgPath: '/hotels/Meghana.png', address: '4th Block, Jayanagar' },
        { id: 6, name: 'Roti Wala', type: 'veg', subDes: 'North Indian, Home Food, Thalis, Chinese, Punjabi, South Indian, Ice Cream', rating: '4.2', costForTwo: '200', discount: '20% of | Use TryNew', imgPath: '/hotels/rotiwala.png', address: '7TH Cross, Btm Layout' }
        ],
    },
    reducers: {
        getRestaurantList: (state, action) => {
            //
        },
    }
});


// Action creators are generated for each case reducer function
export const { getRestaurantList } = restaurantSlice.actions;

export default restaurantSlice.reducer;