export const addItemToCart = (cart: any, { Item, Restaurant }: any) => {
    console.log(cart, Item, Restaurant)
    cart = checkSameRestaurant(Restaurant, cart);
    if (cart.cartItems.findIndex((item: any) => item.id === Item.id) === -1) {
        cart.cartItems.push({ ...Item, count: 1 });
        cart.totalItemCount = totalCount('add', cart.totalItemCount);
        cart.totalAmount = totalAmount('add', Item, cart.totalAmount);
        cart.hotelDetails = { name: Restaurant.name, address: Restaurant.address, imgPath: Restaurant.imgPath, id: Restaurant.id }
    }
    return cart;
}

export const removeItemToCart = (cart: any, itemId: any) => {
    const item = getItem(cart.cartItems, itemId);
    if (item) {
        cart.totalItemCount = totalCount('remove', cart.totalItemCount, item);
        cart.totalAmount = totalAmount('remove', item, cart.totalAmount);
    }
    const itemIndex = cart.cartItems.findIndex((item: any) => item.id === itemId)
    cart.cartItems.splice(itemIndex, 1)
    return cart;
}

export const incrementItemToCart = (cart: any, itemId: any) => {
    const item = getItem(cart.cartItems, itemId)
    if (item) {
        item.count = item.count + 1
        cart.totalItemCount = totalCount('add', cart.totalItemCount);
        cart.totalAmount = totalAmount('add', item, cart.totalAmount);
    }
    return cart
}

export const decrementItemToCart = (cart: any, itemId: any) => {
    const item = getItem(cart.cartItems, itemId)
    if (item) {
        item.count = item.count - 1
    }
    if (item.count < 1) {
        const itemIndex = cart.cartItems.findIndex((item: any) => item.id === itemId)
        cart.cartItems.splice(itemIndex, 1)
    }
    cart.totalItemCount = totalCount('subtract', cart.totalItemCount);
    cart.totalAmount = totalAmount('subtract', item, cart.totalAmount);
    return cart
}

export const clearCartItems = (cart: any) => {
    cart.cartItems = []
    cart.totalItemCount = 0
    cart.totalAmount = 0
    cart.hotelDetails = { name: '', address: '', imgPath: '', id: '' }
    return cart;
}


const getItem = (cartItems: any, id: number) => {
    return cartItems.find((item: any) => item.id === id)
}

const totalCount = (type: string, totalCount: number, item?: any) => {
    switch (type) {
        case 'add': totalCount = totalCount + 1; break;
        case 'subtract': totalCount = totalCount - 1; break;
        case 'remove': totalCount = totalCount - item.count; break;
        default: return;
    }
    return totalCount
}

const totalAmount = (type: string, item: any, totalAmount: any) => {

    switch (type) {
        case 'add': totalAmount = totalAmount + +item.price; break;
        case 'subtract': totalAmount = totalAmount - +item.price; break;
        case 'remove': totalAmount = totalAmount - (item.count * +item.price); break;

        default: return;
    }
    return totalAmount
}

const checkSameRestaurant = (hotelSelected: any, hotelInCart: any) => {

    if (hotelInCart.hotelDetails.id === '' || hotelSelected.id === hotelInCart.hotelDetails.id) return hotelInCart;

    hotelInCart.cartItems = []
    hotelInCart.totalItemCount = 0
    hotelInCart.totalAmount = 0
    hotelInCart.hotelDetails = { name: '', address: '', imgPath: '', id: '' }

    console.log('Hi', hotelSelected, hotelInCart)
    return hotelInCart;
}