import React from 'react';
import { DatePicker, Space } from 'antd';
import './dateRangestyles.scss'
const { RangePicker } = DatePicker;

const DateRange: React.FC = () => {

    return (
        <Space direction="vertical" size={12} className="date__width">
            <RangePicker />
        </Space>
    );
};

export default DateRange;     