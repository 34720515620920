import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddRemoveButton from "../../CommonComponents/addRemoveButton/addRemoveButton";
import LoginSignup from "../../CommonComponents/loginsignup/loginsignup";
import Logo from "../../CommonComponents/Logo/logo";
import UserAction from "../../CommonComponents/useraction/useraction";
import { setManualLoction } from "../../redux/googleMapRedux/gmapslice";
import './cart.styles.scss';
import { decrementCartItem, incrementCartItem, removeCartItem } from "../../redux/cartRedux/cartslice";
import payOrder from "../../utils/razpaycomponent";
import { setPaymentDetails } from "../../redux/paymentRedux/payment_slice";

const CartPage = () => {

    // //loaclState
    const [showLoginPopUp, setShowLoginPopUp] = useState(false)

    const navigate = useNavigate();

    //get restaurant
    let { restaurantId } = useParams();
    let hotelList = useSelector((state: any) => state.restaurants.restaurant)
    let selectedRestaurant = hotelList.find((hotel: any) => hotel.id == restaurantId)

    //get redux state
    const { cartItems, totalAmount } = useSelector((state: any) => state.cart)
    let srcAddress = useSelector((state: any) => state.gmap.currentLocation.formatted_address ? state.gmap.currentLocation?.formatted_address : state.gmap.currentLocation)
    let userLoggedIn = useSelector((state: any) => state.user.currentUser)
    const dispatch = useDispatch();
    const srcChange = (e: any) => {
        dispatch(setManualLoction(e.target.value))
    }

    //Redux Methods

    const itemCountAdd = (itemId: number) => {
        dispatch(incrementCartItem(itemId))
    }

    const itemCountSub = (itemId: number) => {
        dispatch(decrementCartItem(itemId))
    }

    const removeItem = (itemId: number) => {
        dispatch(removeCartItem(itemId))
    }

    //razorpay method
    const checkBuyProcess = async (userId: any) => {
        await payOrder(userId, cartItems, totalAmount + Math.round(totalAmount / 18) + 100, postPayment)
        // console.log(paymentDetails)
    }
    const postPayment = (paymentDetails: any) => {
        if (paymentDetails.status !== "Success") alert('payment failed, Please try again');
        dispatch(setPaymentDetails({ items: paymentDetails.data.items[0], status: paymentDetails.status }));
        navigate('/paymentsuccess')
    }
    return (
        <div className="cart__container">
            <div className="cart__Header">
                <Logo />
                <div className="device__location">
                    <span className="src">
                        <input placeholder="Uthrahalli" value={srcAddress} onChange={(e) => srcChange(e)} required />
                        <img src="/down.png" alt="down" />
                    </span>
                </div>
                <UserAction />
                {showLoginPopUp && !userLoggedIn && <LoginSignup setShowLoginPopUp={setShowLoginPopUp} />}
            </div>
            <h1 style={{ padding: '10px 37px' }}>Cart Checkout</h1>
            <div className="cartItem_details_contianer">
                <div className="cartItem__Container">
                    <div className="hotel__heading">
                        <div className="hotel_details">
                            <h2>{selectedRestaurant.name}</h2>
                            <p>{selectedRestaurant.address}</p>
                        </div>
                        <div className="hotel_img">
                            <img src={selectedRestaurant.imgPath} alt="hotelPic" />
                        </div>
                    </div>

                    {cartItems.map((item: any) => {
                        return (
                            <div key={item.id} className="item__container">
                                <div className="item__description">
                                    {item.imgPath && <img src={item.imgPath} alt="itemPic" />}
                                    <label>{item.name}</label>
                                    <p className="item_desc">{item.desc}</p>
                                    <p className="item_price">Price: &#8377;{item.price}</p>
                                </div>
                                <div className="item__count__total">
                                    <AddRemoveButton imgClass={item.imgPath ? 'overImageButton' : 'standAloneButton'} Count={item.count} itemCountAdd={() => itemCountAdd(item.id)} itemCountSub={() => itemCountSub(item.id)} />
                                    <button onClick={() => removeItem(item.id)}><img className="delete_icon" src="/icons/delete.png" alt="deleteIcon" /></button>
                                </div>

                            </div>
                        )
                    })}
                </div>
                <div className="cartSummary__Container">
                    <h2>Order Summary:</h2>

                    {cartItems.map((item: any) => {
                        return (
                            <div className="order__details" key={item.id}>
                                <p className="order__name">{item.name}:</p>
                                <p>{item.price} X {item.count} : {+item.price * item.count}Rps</p>
                            </div>
                        )
                    })}
                    <div className="order__details">
                        <div className="oder__heading">
                            <p>Order Total:</p>
                            <p>Service Fee:</p>
                            <p>CGST & SGST:</p>
                        </div>
                        <div className="order__value">
                            <p>{totalAmount} Rps</p>
                            <p>100Rps</p>
                            <p>{Math.round(totalAmount / 18)} Rps</p>
                        </div>
                    </div>
                    <h2>Bill Amount:&nbsp;  &#8377;{totalAmount + Math.round(totalAmount / 18) + 100} rps</h2>
                    <button onClick={() => { checkBuyProcess(userLoggedIn.id) }}>CheckOut</button>
                </div>
            </div>
        </div>
    )
}

export default CartPage;