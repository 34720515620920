import React from "react";
import './radiobutton.scss'

const RadioButton = ({ checkSetter, value, reqClass }: any) => {
    console.log(value)
    return (
        <div className="radioGap">
            <input type="radio" defaultChecked={value === 0} name="radio" value={value} className={`${reqClass}`} onChange={(value) => checkSetter(value)} />
            <label>Route<span className="gapfill">{value + 1}</span></label>
        </div>
    )
}
export default RadioButton