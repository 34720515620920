/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const patSlice = createSlice({
    name: 'pat',
    initialState: {
        tripDetails: {},
        routePlan: {},
    },
    reducers: {
        setRoutePath: (state, action) => {
            state.routePlan = action.payload;
        },
        setTripDetails: (state, action) => {
            state.tripDetails = action.payload;
        }
    }
});


// Action creators are generated for each case reducer function
export const { setRoutePath, setTripDetails } = patSlice.actions;

export default patSlice.reducer;
