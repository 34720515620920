import React, { useEffect, useState } from 'react';
import Logo from '../../CommonComponents/Logo/logo';
import './food_style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { setManualLoction } from '../../redux/googleMapRedux/gmapslice';
import UserAction from '../../CommonComponents/useraction/useraction';
import { Link } from 'react-router-dom';
import { Modal, Radio } from 'antd';
import HeadCount from '../../CommonComponents/headcount/headcount';
const FoodModule = () => {
    const options = [{
        id: 1, label: '', totalMax: 45,
        dropDownOptions: [{ id: 'AD', typeOfInd: 'Adult', subLabel: 'Ages 13 or above', maxValue: 36 },
        { id: 'CHLD', typeOfInd: 'Children', maxValue: 9, subLabel: 'Ages 2-12' },
        { id: 'INF', typeOfInd: 'Infants', maxValue: 5, subLabel: 'Under 2' },
        ]
    }]
    const sortHotel = [
        { id: 1, name: 'Veg', filterParam: 'type' },
        { id: 2, name: 'Rating', filterParam: 'rating' },
        { id: 3, name: 'Cost: Low to High', filterParam: 'costForTwo' },
        { id: 4, name: 'Cost: Low to High', filterParam: 'costForTwo' },
    ]
    const bannerImage = [{ id: 1, path: './foodPromo/Promo1.png' },
    { id: 2, path: './foodPromo/Promo2.png' },
    { id: 3, path: './foodPromo/Promo3.png' },
    { id: 4, path: './foodPromo/Promo4.png' }]
    // const hotelList = [{ id: 1, name: 'Kannur Food Point', type: 'veg', subDes: 'Kerala, Chinese', rating: '4.0', costForTwo: '500', discount: '50% of on all orders', imgPath: './hotels/kannurFP.png' },
    // { id: 2, name: 'Empire', type: 'non-veg', subDes: 'North Indian, Kebabs, Biriyani', rating: '4.2', costForTwo: '450', discount: '10% of | Use TryNew', imgPath: './hotels/empire.png' },
    // { id: 3, name: 'Ayodhya Upachar', type: 'veg', subDes: 'Chinese, North Indian, Desserts, South Indian, Chaat', rating: '4.4', costForTwo: '200', discount: '20% of | Use TryNew', imgPath: './hotels/ayodhya.png' },
    // { id: 4, name: 'Kabab Magic', type: 'non-veg', subDes: 'North Indian, Chinese, Biriyani, Tandoor', rating: '4.2', costForTwo: '250', discount: 'Free Delivery', imgPath: './hotels/kababMagic.png' },
    // { id: 5, name: 'Meghana Foods', type: 'non-veg', subDes: 'Biriyani, Andhra, South Indian, Chinese,North Indian, SeaFood', rating: '4.4', costForTwo: '500', discount: 'Free Delivery', imgPath: './hotels/Meghana.png' },
    // { id: 6, name: 'Roti Wala', type: 'veg', subDes: 'North Indian, Home Food, Thalis, Chinese, Punjabi, South Indian, Ice Cream', rating: '4.2', costForTwo: '200', discount: '20% of | Use TryNew', imgPath: './hotels/rotiwala.png' }
    // ]
    const dispatch = useDispatch();
    const srcChange = (e: any) => {
        dispatch(setManualLoction(e.target.value))
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        setIsModalOpen(true)
    }, [])

    let srcAddress = useSelector((state: any) => state.gmap.currentLocation.formatted_address ? state.gmap.currentLocation?.formatted_address : state.gmap.currentLocation)
    let hotelList = useSelector((state: any) => state.restaurants.restaurant)

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const countChange = () => {
        //
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };



    return (
        <div className="foodModule__container">
            <div className="food__Header">
                <Logo />
                <div className="device__location">
                    <span className="src">
                        <input placeholder="Uthrahalli" value={srcAddress} onChange={(e) => srcChange(e)} required />
                        <img src="./down.png" alt="down" />
                    </span>
                </div>
                <UserAction />
            </div>
            <div className='food__Banner'>
                {bannerImage.map(img => {
                    return (
                        <div key={img.id} className="img__container">
                            <img src={img.path} alt="imgPromo" />
                        </div>
                    )
                })}
            </div>
            <div className="food__Filters">
                <div className="hotel__Count">{hotelList.length} Restaurants </div>
                <div className="sort__Container">{sortHotel.map(sort => {
                    return (
                        <button key={sort.id} className="sort_heading">{sort.name}</button>
                    )
                })}</div>
                <div className="filter_option">Filter</div>
            </div>
            <div className="mainContents">
                {hotelList.map((hotel: any) => {
                    return (
                        <Link to={`/restaurants/${hotel.id}`} key={hotel.id} className="item">
                            <div className="imageContainer">
                                <img src={hotel.imgPath} alt="itemImage" />
                            </div>
                            <label>{hotel.name}</label>
                            <p className='hote__subDes'>{hotel.subDes}</p>
                            <div className="hotel__detail">
                                <div className='rating__highlight'>{hotel.rating}</div>
                                <div className="priceSelection">
                                    <p>&#8377;{hotel.costForTwo} FOR TWO</p>
                                </div>
                            </div>
                            <div className='hotel__offers'>
                                {hotel.discount}
                            </div>
                        </Link>

                    )
                })}
            </div>
            <Modal title="More Info" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <label className="headCount">HeadCount</label>
                <HeadCount options={options} countChange={countChange} position={{ top: '0px', left: '499px' }} />
                <Radio value={'petFriendly'}>Pet Friendly</Radio>
                <Radio value={'Disabled'}>Disabled</Radio>
            </Modal>
        </div>
    )
}

export default FoodModule;