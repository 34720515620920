import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../redux/cartRedux/cartslice";
import { fetchRouteStart, setManualLoction } from "../../redux/googleMapRedux/gmapslice";
import LoginSignup from "../loginsignup/loginsignup";
import Logo from "../Logo/logo";
import MapComponent from "../mapcomponent/map_component";
import UserAction from "../useraction/useraction";
import './paymentsuccess.scss'

const PaymentSuccess = () => {
    const openRouteToRestaurant = () => {
        setExpand(true)
    }
    const [showLoginPopUp, setShowLoginPopUp] = useState(false)
    const [expandRoute, setExpand] = useState(false)

    const paymentDetails = useSelector((state: any) => state.payment)
    const cartItems = useSelector((state: any) => state.cart)
    const routes = useSelector((state: any) => state.gmap.routes)
    let srcLocation = useSelector((state: any) => state.gmap.currentLocation.locationLatLng ? state.gmap.currentLocation?.locationLatLng : state.gmap.currentLocation)
    let srcAddress = useSelector((state: any) => state.gmap.currentLocation.formatted_address ? state.gmap.currentLocation?.formatted_address : state.gmap.currentLocation)
    let userLoggedIn = useSelector((state: any) => state.user.currentUser)
    const dispatch = useDispatch();
    const srcChange = (e: any) => {
        dispatch(setManualLoction(e.target.value))
    }
    const orderDetails = useRef({ cartItems: [], totalItemCount: 0, totalAmount: 0, hotelDetails: { name: '', address: '', imgPath: '', id: '' } });


    useEffect(() => {
        if (paymentDetails.paymentStatus === 'Success') {
            orderDetails.current = cartItems
            let destinationInput = orderDetails.current.hotelDetails.address
            dispatch(fetchRouteStart({ srcLocation, destinationInput }))
            dispatch(clearCart());
        }
    }, [])
    console.log(paymentDetails, cartItems);
    return (
        <div className="success__container">
            <div className="success__Header">
                <Logo />
                <div className="device__location">
                    <span className="src">
                        <input placeholder="Uthrahalli" value={srcAddress} onChange={(e) => srcChange(e)} required />
                        <img src="/down.png" alt="down" />
                    </span>
                </div>
                <UserAction />
                {showLoginPopUp && !userLoggedIn && <LoginSignup setShowLoginPopUp={setShowLoginPopUp} />}
            </div>
            <div className="success__details">
                <div className="success__Container">
                    <div className="success__heading">
                        <div className="success_details">
                            <h2>{orderDetails.current.hotelDetails.name}</h2>
                            <p>{orderDetails.current.hotelDetails.address}</p>
                        </div>
                        <div className="hotel_img">
                            <img src="/confirmed.png" alt="hotelPic" />
                        </div>
                    </div>
                    <div className="success__items">
                        {orderDetails.current.cartItems.map((item: any) => {
                            return (
                                <div className="order__details" key={item.id}>
                                    <div><p className="order__name">{item.name}:</p>
                                        <p>&#8377;  {item.price} X {item.count} : {+item.price * item.count}</p></div>
                                    <div>{item.imgPath && <img src={item.imgPath} alt="itemPic" />}</div>
                                </div>
                            )
                        })}
                        <div className="order__details">
                            <div className="oder__heading">
                                <p>Order Total:</p>
                                <p>Service Fee:</p>
                                <p>CGST & SGST:</p>
                            </div>
                            <div className="order__value">
                                <p>&#8377;{orderDetails.current.totalAmount} </p>
                                <p>&#8377;100</p>
                                <p>&#8377;{Math.round(orderDetails.current.totalAmount / 18)} </p>
                            </div>
                        </div>
                        <h2>Bill Amount:&nbsp;  &#8377;{orderDetails.current.totalAmount + Math.round(orderDetails.current.totalAmount / 18) + 100} Paid</h2>
                    </div>
                </div>
            </div>
            <div className="routeContainer">
                <button className="routeButton" onClick={() => { openRouteToRestaurant() }}>Route to Restaurant</button>
                {expandRoute && <div className="route_map_container__expaned">
                    <div className='expandCloseButton' ><button onClick={() => setExpand(false)}>X</button></div>
                    <div className='mapContainer'><MapComponent zoomControl={true} size={{ width: '98%', height: '90%', position: 'absolute', borderRadius: '10px' }} route={routes[0]} /></div>
                </div>}
            </div>
        </div>
    )
}

export default PaymentSuccess;