import React, { useState } from 'react';
import LocationDropdown from '../../../CommonComponents/locationdropdown/locationdropdown';
import { useSwipeable } from 'react-swipeable';
import './carousel_nearbyattraction.scss';
import CarouselItem from '../../../CommonComponents/carousel/carousel';

const CarouselNearbyAttraction = () => {
  const placeDropDown = [
    { id: 1, place: 'Bangalore' },
    { id: 2, place: 'Chennai' },
    { id: 3, place: 'Wayanad' }
  ];
  const carouselPromo = [
    {
      id: 1,
      imagePath: '../nearbyAttractions/lal_bagh.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'lalbhag',
      borderradius: '10px'
    },
    {
      id: 2,
      imagePath: '../nearbyAttractions/bangalorepalace.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'Bangalore Palace',
      borderradius: '10px'
    },
    {
      id: 3,
      imagePath: '../nearbyAttractions/vidhanasoudha.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'vidhanasoudha',
      borderradius: '10px'
    },
    {
      id: 4,
      imagePath: '../nearbyAttractions/shiva.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'Shiva',
      borderradius: '10px'
    },
    {
      id: 6,
      imagePath: '../nearbyAttractions/lal_bagh.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'lalbhag',
      borderradius: '10px'
    },
    {
      id: 7,
      imagePath: '../nearbyAttractions/vidhanasoudha.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'vidhanasoudha',
      borderradius: '10px'
    },
    {
      id: 8,
      imagePath: '../nearbyAttractions/shiva.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'v',
      borderradius: '10px'
    },
    {
      id: 9,
      imagePath: '../nearbyAttractions/lal_bagh.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'lalbhag',
      borderradius: '10px'
    },
    {
      id: 10,
      imagePath: '../nearbyAttractions/vidhanasoudha.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'vidhanasoudha',
      borderradius: '10px'
    },
    {
      id: 11,
      imagePath: '../nearbyAttractions/shiva.png',
      width: '16.66%',
      padding: '12px',
      km: '5',
      attractionName: 'shiva',
      borderradius: '10px'
    }
  ];

  const updateIndex = (newIndex: number) => {
    console.log(newIndex);
    if (newIndex < 0) {
      newIndex = carouselPromo.length / 4;
    } else if (newIndex >= 3) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <div {...handlers} className="attraction__container">
      <div className="attraction__holder">
        <img
          className="left__arrow"
          src="./icons/leftArrow.png"
          alt="leftArrow"
          onClick={() => updateIndex(activeIndex - 1)}
        />
        <img
          className="right__arrow"
          src="./icons/rightArrow.png"
          alt="rightArrow"
          onClick={() => updateIndex(activeIndex + 1)}
        />
        <h2>
          Attractions near you - <LocationDropdown dropDown={placeDropDown} />
        </h2>
        <div className="carousel">
          <div className="inner fade" style={{ transform: `translate(-${activeIndex * 16.6}%)` }}>
            {carouselPromo.map(({ imagePath, id, width, padding, borderradius }) => {
              return (
                <CarouselItem
                  key={id}
                  imagePath={imagePath}
                  width={width}
                  padding={padding}
                  borderradius={borderradius}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselNearbyAttraction;
