import React, { useState } from 'react';
import './filterbutton.scss';


const FilterButton = (props: any) => {

    const [active, setActive] = useState(false);

    const buttonSelect = (option: any, label: any) => {
        setActive(!active)
        console.log(option, label)
        const tempObj = { id: option.id.toString(), value: option.label, category: label }
        props.buttonClick(tempObj);
    }


    return (
        <div className={`${active ? 'active' : 'button'}`} key={props.option.id} >
            <button onClick={() => buttonSelect(props.option, props.selection.label)}>{props.option.label} </button>
        </div>
    );
}

export default FilterButton;