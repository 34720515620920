import React, { useEffect, useState } from 'react';
import './headcount.scss'

const HeadCount = (props: any) => {
    let initialtypeOfPers: { [x: number]: any; } = {};
    props.options.forEach((dropDown: any) => {
        dropDown.dropDownOptions.forEach((option: any) => {
            Object.keys(option).forEach(key => {
                if (key === 'typeOfInd') {
                    initialtypeOfPers[option[key]] = 0;
                }
            })
        })
    })
    // initialtypeOfPers = { ...initialtypeOfPers, option.type = 0 }
    const updateCount = (type: any, val: number) => {
        const tempCount = { ...headCount };
        if (tempCount[type] > -1) {
            tempCount[type] = tempCount[type] + val;
            setTotalCount(totalCount + val)
            console.log(totalCount)
        } else {
            tempCount[type] = 0;
            setTotalCount(totalCount - val)
        }
        setHeadCount(tempCount);

    }

    const openClosedropDown = () => {
        setShowOptions(!showOptions)
    }

    const [headCount, setHeadCount] = useState({ ...initialtypeOfPers })
    const [totalCount, setTotalCount] = useState(0);
    const [showOptions, setShowOptions] = useState(false);
    // console.log(props)
    useEffect(() => {
        props.countChange(totalCount, headCount)
    }, [headCount])
    return (
        <div className="dropdown__HC">
            <p>{totalCount > 0 ? `${totalCount}Guests` : 'Add Guests'}</p>
            <img src='./icons/dropMinicon.png' alt="downArrows" onClick={() => openClosedropDown()} />
            {showOptions && <div className="show__option" style={{ top: props.position.top, left: props.position.left }}>
                <section>
                    <div className="hc__conatiner">
                        {props.options.map((dropDown: any) => {
                            return (
                                dropDown.dropDownOptions.map((option: any) => {
                                    return (
                                        <div key={option.id} className="indv__Options">
                                            <div className="option_label">
                                                <label>{option.typeOfInd}</label>
                                                <p>{option.subLabel}</p>
                                            </div>
                                            <div className='count_IncDec'>
                                                <button disabled={headCount[option.typeOfInd] === 0} onClick={() => updateCount(option.typeOfInd, -1)}><img className="plusminus" src="./icons/minus.png" /></button>
                                                <p>{headCount[option.typeOfInd]}</p>
                                                <button disabled={headCount[option.typeOfInd] === option.maxValue || totalCount === dropDown.totalMax} onClick={() => updateCount(option.typeOfInd, 1)}><img className="plusminus" src="./icons/plus.png" /></button>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        })}
                    </div>
                </section>
            </div>}
        </div>
    )
}
export default HeadCount;