import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "./firebase.util";
import axios from "axios";
const payOrder = async (userId,cartItems,cartItemTotal,postPayment)=>{
    let paymentDetails='';
    const userRef = doc(firestore, "users", userId);
    await updateDoc(userRef,{
        CartItems : [...cartItems]
    })
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onerror = () => {
          alert('Razorpay SDK failed to load. Are you online?');
        };
        script.onload = async () => {
          try {
            const result = await axios.post('https://us-central1-foodtolls-bedummy.cloudfunctions.net/app/razorpay/create-order', {
              amount: cartItemTotal+'00',
            });
            const { amount, id: order_id, currency } = result.data;
            const {
              data: { key: razorpayKey },
            } = await axios.get('https://us-central1-foodtolls-bedummy.cloudfunctions.net/app/razorpay/get-razorpay-key');
    
            const options = {
              key: razorpayKey,
              amount: amount.toString(),
              currency: currency,
              name: 'FoodTolls',
              description: 'Food Order',
              order_id: order_id,
              handler: async function (response) {
                const result = await axios.post('https://us-central1-foodtolls-bedummy.cloudfunctions.net/app/razorpay/pay-order', {
                  amount: amount,
                  razorpayPaymentId: response.razorpay_payment_id,
                  razorpayOrderId: response.razorpay_order_id,
                  razorpaySignature: response.razorpay_signature,
                });
                if(result.data.status === 'Success'){
                  await updateDoc(userRef,{
                    order_id : {orderItem:[...cartItems],id:order_id,amount:amount.toString(),status:'paid'}
                })
                    postPayment(result.data)
                }
               
              },
              prefill: {
                name: 'Food Tolls',
                email: 'foodTollsindia@gmail.com',
                contact: '111111',
              },
              notes: {
                address: '',
              },
              theme: {
                color: '#528FF0',
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } catch (err) {
            alert(err);
          }
        };
        document.body.appendChild(script);
        return paymentDetails;
       
       
 }

 export default payOrder