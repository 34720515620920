import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { emailSignUpStart } from "../../../redux/userredux/user_slice";
import CustomButton from "../../custombutton/custombutton";
import FormInput from "../../form-input/form-input.component";
import './signup.scss';

const Signup = () => {
    const [signUpDetails, setSignUpDetails]: any = useState({ displayName: '', email: '', password: '', confirmPassword: '' })
    const dispatch = useDispatch();
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const { email, password, confirmPassword } = signUpDetails;
        // const { emailSignUpStart } = this.props
        if (password !== confirmPassword) {
            alert("Passwords don't match")
            console.log(email)
        }
        dispatch(emailSignUpStart({ email, password }))
        // emailSignUpStart(email, password)
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setSignUpDetails((prevState: any) => ({
            ...prevState, [name]: value
        }))
        console.log(signUpDetails);
        // this.setState({[name]:value})
    }
    return (
        <div className="signUp__Conatiner">
            <form className='sign-up-form' onSubmit={handleSubmit}>
                <FormInput type='text' name='displayName' value={signUpDetails.displayName}
                    handleChange={handleChange}
                    label='Display Name' required />

                <FormInput type='email' name='email' value={signUpDetails.email}
                    handleChange={handleChange}
                    label='Email' required />

                <FormInput type='password' name='password' value={signUpDetails.password}
                    handleChange={handleChange}
                    label='Password' required />

                <FormInput type='password' name='confirmPassword' value={signUpDetails.confirmPassword}
                    handleChange={handleChange}
                    label='Confirm password' required />

                <CustomButton type='submit'>Sign Up</CustomButton>
            </form>
        </div>
    )
}

export default Signup