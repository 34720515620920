import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { SignInStart } from "../../../redux/userredux/user_slice";
import CustomButton from "../../custombutton/custombutton";
import FormInput from "../../form-input/form-input.component";
import './login.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        // const {emailSignInStart} = this.props;
        dispatch(SignInStart({ email, password }))
    }

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        switch (name) {
            case 'email': setEmail(value); break;
            case 'password': setPassword(value); break;
        }
    }
    return (
        <div className="login__Conatiner">
            <form onSubmit={handleSubmit}>
                <FormInput name='email' type='email' value={email} handleChange={handleChange} label='Email' required />
                <FormInput name='password' type='password' value={password} handleChange={handleChange} label='Password' required />
                <div className="buttons">
                    <CustomButton key="signin" type='submit'>Sign In</CustomButton>
                    <CustomButton type="button" key="googlesignin" isSignInWithGoogle> SIGN IN WITH GOOGLE </CustomButton>
                </div>
            </form>

        </div>
    )
}

export default Login