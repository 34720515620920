import React, { useEffect, useState } from "react";
import BlurEffect from "../blurrEffect/blureffect";
import Login from "./login/login";
import './loginsignup.scss'
import Signup from "./signup/signup";

const LoginSignup = ({ setShowLoginPopUp }: any) => {
    const [typeOfAction, settypeofAction] = useState('login');
    const [blurEffect, setBlur] = useState(true);
    useEffect(() => {
        if (!blurEffect) {
            setShowLoginPopUp(false)
        }
    })
    const setActive = (type: string) => {
        console.log(type)
        settypeofAction(type)
    }
    return (<div>
        <div className="login_signup_container">
            <h1>Login In <span style={{ fontSize: '15px' }}>or</span> Sign Up</h1>
            <div className="tab">
                <button className={`${typeOfAction === 'login' ? 'active' : ''}`} onClick={() => setActive('login')}>Login</button>
                <button className={`${typeOfAction === 'signup' ? 'active' : ''}`} onClick={() => setActive('signup')}>SignUp</button>
            </div>
            <div className="form__container">
                {typeOfAction === 'login' ? <Login /> : <Signup />}
            </div>
        </div>
        <BlurEffect blurEffect={blurEffect} setBlur={setBlur} />
    </div>
    )
}

export default LoginSignup;