import React, { useEffect, useState } from 'react';
import SearchDialog from '../searchdialog/searchdialog';
import './globalsearch.scss';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationStart, setManualLoction, fetchRouteStart } from '../../redux/googleMapRedux/gmapslice';
import { useNavigate } from 'react-router-dom';
import { setRoutePath } from '../../redux/planatripredux/pat_slice';
import BlurEffect from '../blurrEffect/blureffect';
const GlobalSearch = () => {

  const srcChange = (e: any) => {
    dispatch(setManualLoction(e.target.value))
  }

  const destChange = (e: any) => {
    updateDest(e.target.value)
  }

  const [destinationInput, updateDest] = useState('');
  let navigate = useNavigate();
  let srcAddress = useSelector((state: any) => state.gmap.currentLocation.formatted_address ? state.gmap.currentLocation?.formatted_address : state.gmap.currentLocation)
  let srcLocation = useSelector((state: any) => state.gmap.currentLocation.locationLatLng ? state.gmap.currentLocation?.locationLatLng : state.gmap.currentLocation)
  let srcCity = useSelector((state: any) => state.gmap.currentLocation.city ? state.gmap.currentLocation?.city : state.gmap.currentLocation)
  const dispatch = useDispatch();
  const getLocation = bindActionCreators(getLocationStart, dispatch)

  const [blurEffect, setBlur] = useState(false);
  // const [localLocation, setLocation] = useState(location);
  useEffect(() => {
  });
  //getting and setting routes after submit or skip
  const getRoutes = (type: string, selectOption: any) => {
    dispatch(setRoutePath([srcCity, destinationInput]))
    dispatch(fetchRouteStart({ srcLocation, destinationInput, selectOption }))
    navigate('/planatrip', { replace: false })
  }


  return (
    <div>
      <div className="search_Container" onClick={() => setBlur(true)}>
        <div className="src_location">
          <img src="./geoPin.png" alt="geoPin" onClick={() => getLocation()} />
          <span className="src">
            <input placeholder="Uthrahalli" value={srcAddress} onChange={(e) => srcChange(e)} required />
          </span>
          <img src="./down.png" alt="down" />
        </div>
        <div className="dest_find">
          <img src="./search.png" alt="searchIcon" />
          <div className="dest">
            <input placeholder="Search Destination" value={destinationInput} onChange={(e) => destChange(e)} />
          </div>
          {blurEffect && destinationInput !== '' && <SearchDialog getRoutes={getRoutes} />}
        </div>
      </div>
      {/* <div className={blurEffect ? 'blur_effect' : ''} onClick={() => setBlur(false)}></div> */}
      {<BlurEffect blurEffect={blurEffect} setBlur={setBlur} />}
    </div>
  );
};

export default GlobalSearch;
