import React from 'react';
import HMBanner from './banner/banner';
import './hm_footer.scss';

const HMFooter = () => {
  return (
    <div className="footer__container">
      <HMBanner />
      <div className="footer__content">
        <img className="FT_LOGO" src="./footer/FT_footerlogo.png" alt="" />
        <div className="content">
          <div className="sub_points">
            <li>About Us</li>
            <li>Careers</li>
            <li>FAQs</li>
            <li>Blog</li>
            <li>Gift Cards</li>
          </div>
          <p>© 2022 Food Tolls. All Rights Reserved.</p>
        </div>
        <div className="socialMedia__icon">
          <img src="./footer/socialMediaicon.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HMFooter;
