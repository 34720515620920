/* eslint-disable */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getCurrentuser, auth,
    createUserProfileDocument,
} from '../../utils/firebase.util';
import { emailSignUpFailure, emailSignUpSuccess, SignInFailure, SignInSuccess } from './user_slice';

// create or fetch user from fireStore
export function* getSnapshotFromUserAuth(userAuth: any): any {
    try {
        const userRef = yield call(createUserProfileDocument, userAuth);
        const userSnapshot = yield userRef.get();
        yield put(SignInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
    } catch (error) {
        yield put(SignInFailure(error));
    }
}

//checkUserLoggedin status
export function* onCheckUserSession() {
    yield takeLatest('user/checkCurrentUser', isUserAuthenticated);
}

export function* isUserAuthenticated(): any {
    try {
        const userAuth = yield getCurrentuser()
        if (!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth);
    } catch (error) {
        // yield put(SignInFailure(error));
    }
}


//userSignup 
export function* signUpStart() {
    yield takeLatest('user/emailSignUpStart', signUp);
}

export function* signUp({ payload: { email, password } }: any) {
    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, password);
        if (!user) return;
        yield put(emailSignUpSuccess({ email, password }));
    } catch (error) {
        yield put(emailSignUpFailure(error));
    }
}


//userSignIn when signup success
export function* onEmailSignUpSuccess() {
    yield takeLatest('user/SignInStart', signInWithEmail);
}

export function* signInWithEmail({ payload: { email, password } }: any) {
    console.log(email, password);
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        console.log(user)
        yield getSnapshotFromUserAuth(user);
    } catch (error) {
        yield put(SignInFailure(error));
    }
}

//userSgnIn
export function* onEmailSignInStart() {
    yield takeLatest('user/SignInStart', signInWithEmail);
}


export function* userSaga() {
    yield all([call(onCheckUserSession), call(signUpStart), call(onEmailSignUpSuccess), call(onEmailSignInStart), call(onCheckUserSession)]);
}




