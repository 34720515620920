import React from 'react';
import './carousel.scss';

interface carouselItemType {
  imagePath?: string;

  width?: string;

  padding?: string;

  borderradius?: string;

  position?: any;

  left?: string;

  top?: string;
}

const CarouselItem: React.FC<carouselItemType> = ({
  imagePath,
  width,
  padding,
  borderradius,
  position,
  left,
  top
}) => {
  return (
    <div
      className="carousel-item"
      style={{ maxWidth: width, padding: padding, position: position, left: left, top: top }}>
      <img src={imagePath} alt="FTHighlights" style={{ borderRadius: borderradius }} />
    </div>
  );
};

export default CarouselItem;
