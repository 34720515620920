import React, { useEffect, useState } from 'react';
import './carousel_highlights.scss';
import { useSwipeable } from 'react-swipeable';
import CarouselItem from '../../../CommonComponents/carousel/carousel';
const CarouselHighlights = () => {
  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = carouselImage.length - 1;
    } else if (newIndex >= carouselImage.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const carouselImage = [
    { id: 1, imagePath: './FTHIGHLIGHT.png', width: '100%' },
    { id: 2, imagePath: './FTHIGHLIGHT.png', width: '100%' },
    { id: 3, imagePath: './FTHIGHLIGHT.png', width: '100%' }
  ];
  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}>
      <div className="inner fade" style={{ transform: `translate(-${activeIndex * 100}%)` }}>
        {carouselImage.map(({ imagePath, id, width }) => {
          return <CarouselItem key={id} imagePath={imagePath} width={width} />;
        })}
      </div>
      <div className="active__Dots">
        {carouselImage.map((imagePath, index) => {
          return index === activeIndex ? (
            <img key={index} src="./selectDot.png" alt="dot" />
          ) : (
            <img key={index} src="./dot.png" alt="dot" />
          );
        })}
      </div>
    </div>
  );
};

export default CarouselHighlights;
