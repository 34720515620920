import React from "react";
import './logo.scss'

const Logo = () => {
    return (
        <div className="logo">
            <img src="/FoodTollsLogo.png" alt="Logo" />
        </div>
    )
}

export default Logo;