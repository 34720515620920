import React from "react";
import RouteBanner from "./routebanner/routebanner";
import './routeplan.scss';
import RoutesComponent from "./routes/routes";
const PlanATrip = ({ isLoaded }: any) => {

    return (
        <div className="routeplan__container">
            <RouteBanner />
            <div className="set__background">
                {isLoaded && <RoutesComponent />}
            </div>
        </div>
    )

}

export default PlanATrip