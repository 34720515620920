import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginSignup from "../loginsignup/loginsignup";
import './useraction.scss';

const UserAction = ({ restaurantId }: any) => {

    let userLoggedIn = useSelector((state: any) => state.user.currentUser)
    let totalCart = useSelector((state: any) => state.cart.totalItemCount)
    const [showloginOptions, setloginOptions] = useState(false)
    const [showLoginPopUp, setShowLoginPopUp] = useState(false)
    return (<div className="signIn_Out_Icon">
        <Link to='/'><img src="/icons/home__icon.png" alt="loginIcon" /></Link>
        {userLoggedIn ?
            <div className="row" onMouseOver={() => setloginOptions(true)} onMouseOut={() => setloginOptions(false)} onClick={() => setloginOptions(!showloginOptions)}>
                <img src="/icons/loggedIn.png" alt="loggedInIcon" className="imgIcon logged__In" />
                {/* {showloginOptions &&
                    <div className="signIn_OUT_Dropdown">
                        <p>Welcome</p>
                        <p>To access account and manage bookings/orders</p>
                        <button onClick={() => setShowLoginPopUp(true)}>LOGIN / SIGNUP</button>
                    </div>} */}
                <Link to={`/cart/${restaurantId}`} ><div className="badge__button">
                    <div className="badge">{totalCart}</div>
                    <img src="/icons/cart.png" alt="cartIcon" className="imgIcon" />
                </div></Link>

            </div>
            :

            <div className="signIn_Out" onMouseOver={() => setloginOptions(true)} onMouseOut={() => setloginOptions(false)} onClick={() => setloginOptions(!showloginOptions)}>
                <img src="/loginIcon.png" alt="loginIcon" />
                {showloginOptions &&
                    <div className="signIn_OUT_Dropdown">
                        <p>Welcome</p>
                        <p>To access account and manage bookings/orders</p>
                        <button onClick={() => setShowLoginPopUp(true)}>LOGIN / SIGNUP</button>
                    </div>}
            </div>}
        {showLoginPopUp && <LoginSignup setShowLoginPopUp={setShowLoginPopUp} />}
    </div>
    )
}

export default UserAction