import React from 'react';
import NavBar from '../../CommonComponents/Navbar/navbar';
import CarouselHighlights from './carousel_highlights/carousel_highlights';
import CarouselPromo from './carousel_promo/carousel_promo';
import HMFooter from './footer/hm_footer';
import GiftCard from './gift/giftcard';
import './homepage.scss';
import CarouselNearbyAttraction from './nearby_attractions/carousel_nearbyattraction';
import RecentSearch from './recent_search/recent-search';
import ShowRecommendTrip from './showrecommendtrips/showrecommend';
import WhyFoodTolls from './whyFt/whyFoodTolls';

const HomePage = () => {
  return (
    <div>
      <div className="home__page">
        <NavBar />
        <CarouselHighlights />
        <CarouselPromo />
        <RecentSearch />
        <ShowRecommendTrip />
        <WhyFoodTolls />
      </div>
      <CarouselNearbyAttraction />
      <GiftCard />
      <HMFooter />
    </div>
  );
};

export default HomePage;
