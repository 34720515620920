import React from "react";
import './blureffect.scss';

const BlurEffect = ({ blurEffect, setBlur }: any) => {
    console.log(blurEffect)
    return (
        <div className={blurEffect ? 'blur_effect' : ''} onClick={() => setBlur(false)} ></div>
    )
}

export default BlurEffect