import React from 'react';
import LocationDropdown from '../../../CommonComponents/locationdropdown/locationdropdown';
import './showrecommend.scss';

const ShowRecommendTrip = () => {
  const placeDropDown: { id: number; place: string }[] = [
    { id: 1, place: 'Bangalore' },
    { id: 2, place: 'Chennai' },
    { id: 3, place: 'Wayanad' }
  ];
  const recommendItemFull = [
    {
      id: 1,
      imagePath: './recommend/ooty.svg',
      recommendLocation: 'Ooty',
      locationType: ['Garden', 'Mountain'],
      KM: '265',
      routeCount: 3,
      restCount: 14,
      stayCount: 12,
      tollCount: 5
    },
    {
      id: 2,
      imagePath: './recommend/Gokarna.svg',
      recommendLocation: 'Gokarna',
      locationType: ['Beach', 'Temple'],
      KmFromSrc: '425',
      routeCount: 5,
      restCount: 23,
      stayCount: 15,
      tollCount: 7
    },
    {
      id: 3,
      imagePath: './recommend/Coorg.svg',
      recommendLocation: 'Coorg',
      locationType: ['Forest', 'Mountain'],
      KmFromSrc: '200',
      routeCount: 6,
      restCount: 44,
      stayCount: 17,
      tollCount: 1
    },
    {
      id: 4,
      imagePath: './recommend/hampi.png',
      recommendLocation: 'hampi',
      locationType: ['Hisoric', 'Mountain'],
      KmFromSrc: '675',
      routeCount: 2,
      restCount: 29,
      stayCount: 18,
      tollCount: 0
    },
    {
      id: 5,
      imagePath: './recommend/Mahabalipuram.svg',
      recommendLocation: 'Mahabalipuram',
      locationType: ['Art Museums', 'Architectural Buildings', 'Historic Sites'],
      KmFromSrc: '365',
      routeCount: 3,
      restCount: 35,
      stayCount: 23,
      tollCount: 7
    },
    {
      id: 6,
      imagePath: './recommend/Mahabalipuram.svg',
      recommendLocation: 'Wayanad',
      locationType: ['Forest', 'Mountain'],
      KmFromSrc: '210',
      routeCount: 2,
      restCount: 14,
      stayCount: 20,
      tollCount: 9
    }
  ];

  const typeOfTravel = [
    { id: 1, travelType: 'One day Trip' },
    { id: 2, travelType: 'Two day Trip' },
    { id: 3, travelType: 'Trekking' },
    { id: 4, travelType: 'Cultural' },
    { id: 5, travelType: 'Romantic' },
    { id: 6, travelType: 'Beaches' },
    { id: 7, travelType: 'Mountain' }
  ];
  const recommendItem = recommendItemFull.splice(0, 5);
  return (
    <div className="recommend__container">
      <div className="recommend__heading">
        <h1>
          Recommended trips - <LocationDropdown dropDown={placeDropDown} />
        </h1>
        <p>View all</p>
      </div>
      <div className="typeOfTravel__filters">
        {typeOfTravel.map(({ id, travelType }) => {
          return (
            <div className="filter__options" key={id}>
              {travelType}
            </div>
          );
        })}
      </div>
      <div className="recommenedTrip__display_conatiner">
        <div className="recommend__minDisp">
          {recommendItem.map(
            ({ id, imagePath, recommendLocation, locationType }, index) =>
              index <= 3 && (
                <div
                  className="tripMinContainer"
                  style={{ backgroundImage: `url(${imagePath}` }}
                  key={id}>
                  <div>
                    <img
                      src="./recommend/heart_wishlist.png"
                      alt="heart"
                      className="heart__Wishlist"
                    />
                    <h2 className="location_title">{recommendLocation}</h2>
                    <div className="locationType__container">
                      {locationType.map((type, index) => {
                        return (
                          <p className="location_type" key={index}>
                            {type} <span className="dot__Seperator">.</span>
                          </p>
                        );
                      })}
                    </div>
                    <img src="./recommend/arrow-right.png" alt="arrow" className="arrow__go" />
                  </div>
                </div>
              )
          )}
        </div>
        {recommendItem[4] && (
          <div className="recommend__maxDisp" key={recommendItem[4].id}>
            <div
              className="tripMaxContainer"
              style={{ backgroundImage: `url(${recommendItem[4].imagePath}` }}>
              <img src="./recommend/heart_wishlist.png" alt="heart" className="heart__Wishlist" />
              <div className="info__container">
                <h2>{recommendItem[4].recommendLocation}</h2>
                <div className="locationType__container">
                  {recommendItem[4].locationType.map((type, index) => {
                    return (
                      <p className="location__type" key={index}>
                        {type} <span className="dot__Seperator">.</span>
                      </p>
                    );
                  })}
                </div>
                <div className="location__info">
                  KM: {recommendItem[4].KmFromSrc} | Routes: {recommendItem[4].routeCount} | Food:{' '}
                  {recommendItem[4].restCount} | Stay:{recommendItem[4].stayCount} | Toll:{' '}
                  {recommendItem[4].tollCount}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowRecommendTrip;
