import React, { useState } from 'react';
import './carousel_promo.scss';
import { useSwipeable } from 'react-swipeable';
import CarouselItem from '../../../CommonComponents/carousel/carousel';

const CarouselPromo = () => {
  const updateIndex = (newIndex: number) => {
    console.log(newIndex);
    if (newIndex < 0) {
      newIndex = carouselPromo.length - 1;
    } else if (newIndex >= carouselPromo.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  const [activeIndex, setActiveIndex] = useState(0);
  // const [paused, setPaused] = useState(false);
  // useEffect(()=>{
  //     const interval = setInterval(()=>{
  //         if(!paused){
  //             updateIndex(activeIndex + 1);
  //         }
  //     },3000);
  //     return()=>{
  //         if(interval) {
  //             clearInterval(interval);
  //         }
  //     }
  // })

  const carouselPromo = [
    {
      id: 1,
      imagePath: './promo/cashback20.png',
      width: '32.5%',
      padding: '6px',
      borderradius: '10px'
    },
    { id: 2, imagePath: './promo/30off.png', width: '32.5%', padding: '6px', borderradius: '10px' },
    {
      id: 3,
      imagePath: './promo/FlashSale.png',
      width: '32.5%',
      padding: '6px',
      borderradius: '10px'
    },
    {
      id: 4,
      imagePath: './promo/cashback20.png',
      width: '32.5%',
      padding: '6px',
      borderradius: '10px'
    },
    { id: 5, imagePath: './promo/30off.png', width: '32.5%', padding: '6px', borderradius: '10px' },
    {
      id: 6,
      imagePath: './promo/FlashSale.png',
      width: '32.5%',
      padding: '6px',
      borderradius: '10px'
    }
  ];
  return (
    <div {...handlers} className="promo__Cotainer">
      <div className="promo_Heading">
        <h1>Promo</h1>
        <div className="arrow_pos">
          <img
            src="./icons/leftMiniArrow.png"
            alt="leftArrow"
            onClick={() => updateIndex(activeIndex - 1)}
          />
          <img
            src="./icons/rightMiniArrow.png"
            alt="rightArrow"
            onClick={() => updateIndex(activeIndex + 1)}
          />
        </div>
      </div>
      <div className="carousel">
        <div className="inner fade" style={{ transform: `translate(-${activeIndex * 33.5}%)` }}>
          {carouselPromo.map(({ imagePath, id, width, padding, borderradius }) => {
            return (
              <CarouselItem
                key={id}
                imagePath={imagePath}
                width={width}
                padding={padding}
                borderradius={borderradius}
              />
            );
          })}
        </div>
        {/* <button onClick={()=>updateIndex(activeIndex + 1)}>Next</button> */}
        {/* <div className="active__Dots">
                {carouselPromo.map((imagePath,index)=>{
                    return index === activeIndex ?<img key={index} src="./selectDot.png" alt="dot" />:<img key={index} src="./dot.png" alt="dot" />
                })}
                </div> */}
      </div>
    </div>
  );
};

export default CarouselPromo;
