import React, { useState } from "react";
import './customroute.scss';
import MapComponent from '../../../../CommonComponents/mapcomponent/map_component';
const CustomRoute = ({ defaultRoute }: any) => {
    console.log(defaultRoute)
    const [createRouteExpand, setcreateRouteExpand] = useState(false);
    return (
        <div className="route__item_custom">
            <div className="add__route__container">
                <img src="./icons/addRoute.png" alt="addRouteIcon" onClick={() => { setcreateRouteExpand(true) }} />
                <p>Create your route</p>
            </div>
            {createRouteExpand && <div className="route_map_container__expaned">
                <div className='expandCloseButton__custom' ><h3>Create new route</h3><button onClick={() => setcreateRouteExpand(false)}>X</button></div>
                <div className='custom_route_edit_container'>
                    <div className="edit_route"></div>
                    <div className='mapContainer__custom'><MapComponent zoomControl={true} size={{ width: '98%', height: '90%', position: 'absolute', borderRadius: '10px' }} route={defaultRoute} /></div>
                </div>
            </div>}
        </div>

    )
}

export default CustomRoute