/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { addItemToCart, clearCartItems, decrementItemToCart, incrementItemToCart, removeItemToCart } from './cart.util';
export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cartItems: [],
        totalItemCount: 0,
        totalAmount: 0,
        hotelDetails: { name: '', address: '', imgPath: '', id: '' }
    },
    reducers: {
        addCartItem: (state: any, action) => {
            state = addItemToCart(state, action.payload)
        },
        incrementCartItem: (state: any, action) => {
            state = incrementItemToCart(state, action.payload)
        },
        decrementCartItem: (state: any, action) => {
            state = decrementItemToCart(state, action.payload)
        },
        removeCartItem: (state: any, action) => {
            state = removeItemToCart(state, action.payload)
        },
        clearCart: (state: any) => {
            state = clearCartItems(state)
        }
    }
});


// Action creators are generated for each case reducer function
export const { addCartItem, incrementCartItem, decrementCartItem, removeCartItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer;