/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const gmapSlice = createSlice({
  name: 'gmap',
  initialState: {
    currentLocation: 'Bangalore',
    isLoading: false,
    errorMessage: '',
    routes: [],
    routePath: []
  },
  reducers: {
    getLocationStart: (state) => {
      state.isLoading = true;
    },

    fetchLocationSuccess: (state, action) => {
      state.currentLocation = action.payload;
      state.isLoading = false;
      state.errorMessage = ''
    },
    fetchLocationFailure: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    setManualLoction: (state, action) => {
      state.currentLocation = action.payload;
      state.isLoading = false;
      state.errorMessage = ''
    },
    fetchRouteStart: (state, action) => {
      state.isLoading = true;
    },
    fetchRouteSuccess: (state, action) => {
      state.routes = action.payload;
      state.isLoading = false;
      state.errorMessage = ''
    },
    fetchRouteFailure: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
  }
});


// Action creators are generated for each case reducer function
export const { getLocationStart, fetchLocationSuccess, fetchLocationFailure, setManualLoction, fetchRouteStart, fetchRouteSuccess, fetchRouteFailure } = gmapSlice.actions;

export default gmapSlice.reducer;
