import React, { useState } from "react";
import './restaurant_styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { setManualLoction } from "../../redux/googleMapRedux/gmapslice";
import Logo from "../../CommonComponents/Logo/logo";
import UserAction from "../../CommonComponents/useraction/useraction";
import { useParams } from "react-router-dom";
import LoginSignup from "../../CommonComponents/loginsignup/loginsignup";
import { addCartItem, decrementCartItem, incrementCartItem } from "../../redux/cartRedux/cartslice";
import AddRemoveButton from "../../CommonComponents/addRemoveButton/addRemoveButton";
const RestaurantComponent = () => {

    //getParams
    let { restaurantId } = useParams();

    // //loaclState
    const [showLoginPopUp, setShowLoginPopUp] = useState(false)

    //get redux state
    let hotelList = useSelector((state: any) => state.restaurants.restaurant)
    let cartItem = useSelector((state: any) => state.cart.cartItems)
    let selectedRestaurant = hotelList.find((hotel: any) => hotel.id == restaurantId)
    let srcAddress = useSelector((state: any) => state.gmap.currentLocation.formatted_address ? state.gmap.currentLocation?.formatted_address : state.gmap.currentLocation)
    let userLoggedIn = useSelector((state: any) => state.user.currentUser)
    //dispatch state
    const dispatch = useDispatch();
    const srcChange = (e: any) => {
        dispatch(setManualLoction(e.target.value))
    }

    //methods
    const addItem = (foodItem: { id: number, veg: boolean, imgPath: string, name: string, price: string, desc: string, type: string }) => {
        if (userLoggedIn) {
            console.log(foodItem)
            dispatch(addCartItem({ Item: foodItem, Restaurant: selectedRestaurant }))
        } else {
            setShowLoginPopUp(true)
        }

    }

    const itemCountAdd = (itemId: number) => {
        dispatch(incrementCartItem(itemId))
    }

    const itemCountSub = (itemId: number) => {
        dispatch(decrementCartItem(itemId))
    }



    return (
        <div className="restaurant__container">
            <div className="restaurant__Header">
                <Logo />
                <div className="device__location">
                    <span className="src">
                        <input placeholder="Uthrahalli" value={srcAddress} onChange={(e) => srcChange(e)} required />
                        <img src="/down.png" alt="down" />
                    </span>
                </div>
                <UserAction restaurantId={selectedRestaurant.id} />
                {showLoginPopUp && !userLoggedIn && <LoginSignup setShowLoginPopUp={setShowLoginPopUp} />}
            </div>
            <div className='restaurant_banner'>
                <img className="resImage" src={selectedRestaurant.imgPath} alt="restImg" />
                <div className="restaurant_desc">
                    <label>{selectedRestaurant.name}</label>
                    <p className="restaurant_desc">{selectedRestaurant.subDes}</p>
                    <p className="restaurant_address">{selectedRestaurant.address}</p>
                    <div className="restaurant_detail">
                        <div className='rating__highlight'>{selectedRestaurant.rating}</div> |
                        <div className="priceSelection">
                            <p>&#8377;{selectedRestaurant.costForTwo} Cost for two</p>
                        </div>
                    </div>
                </div>
                <div className="restaurants_offer">
                    <div className="offerHeading">Offers</div>
                    <div className="discount_details">{selectedRestaurant.discount}</div>
                </div>
            </div>
            <div className="menu_container">
                <div className="sub_categories">
                    {selectedRestaurant.foodMenu.types.map((mainType: any, index: number) => {
                        return (
                            <a key={index} href={`#${mainType}`}>{mainType}</a>
                        )
                    })}
                </div>
                <div className="menu_list">
                    {selectedRestaurant.foodMenu.types.map((mainType: any, index: number) => {
                        return (
                            <div key={index} className="sub_categories__Expand" >
                                <label id={mainType}>{mainType}</label>
                                {selectedRestaurant.foodMenu.menu.map((foodItem: { id: number, veg: boolean, imgPath: string, name: string, price: string, desc: string, type: string }) => {
                                    return (
                                        foodItem.type === mainType ?
                                            <div key={foodItem.id} className="foodItem__container">
                                                <div className="foodItem__desc">
                                                    <label className="food_label">{foodItem.name}</label>
                                                    <p>&#8377;{foodItem.price}</p>
                                                    <p className="desc">{foodItem.desc}</p>
                                                </div>
                                                <div className="img__add">
                                                    {foodItem.imgPath && <img className="foodItem__img" src={foodItem.imgPath} />}
                                                    {cartItem.find((item: any) => item.id === foodItem.id)?.count > 0 ?
                                                        <AddRemoveButton imgClass={foodItem.imgPath ? 'overImageButton' : 'standAloneButton'} Count={cartItem.find((item: any) => item.id === foodItem.id)?.count} itemCountAdd={() => itemCountAdd(foodItem.id)} itemCountSub={() => itemCountSub(foodItem.id)} /> :
                                                        <button className={foodItem.imgPath ? 'overImage' : 'standAlone'} onClick={() => addItem(foodItem)}>Add</button>}

                                                </div>

                                            </div>
                                            : ''
                                    )
                                })}
                            </div>
                        )
                    })}

                </div>
            </div>


        </div>
    )
}

export default RestaurantComponent


