import React from 'react';
import DateRange from '../dateRangecomponent/daterangecoponent';
import FilterButton from '../filterbutton/filterbutton';
import HeadCount from '../headcount/headcount';
import './searchdialog.scss'
const SearchDialog = (props: any) => {
    const selectOption: { id: string, value: string, category: string, expandedValue?: {} }[] = [];
    const onSelect = (e: any) => {
        let eventType = e.target;
        const tempObj = { id: eventType.value, value: eventType.name, category: eventType.id }
        selectOption.push(tempObj)
    }

    const buttonClick = (tempObj: any) => {
        if (selectOption.findIndex(option => (option.category === tempObj.category && option.id === tempObj.id)) === -1) {
            selectOption.push(tempObj)
        } else if (selectOption.findIndex(option => (option.category === tempObj.category && option.id === tempObj.id)) > -1) {
            const itemIndex = selectOption.findIndex(option => (option.category === tempObj.category && option.id === tempObj.id));
            selectOption.splice(itemIndex, 1);
        }
        // console.log(selectOption)
    }

    const getRoutes = (type: string) => {
        props.getRoutes(type, selectOption);
    }


    const countChange = (totalCount: string, headCount: any) => {
        const tempObj = { id: '1', value: totalCount, category: 'headCount', expandedValue: headCount }
        if (selectOption.findIndex(option => (option.category === 'headCount')) === -1) {
            selectOption.push(tempObj)
        } else if (selectOption.findIndex(option => (option.category === tempObj.category && option.id === tempObj.id)) > -1) {
            const itemIndex = selectOption.findIndex(option => (option.category === tempObj.category && option.id === tempObj.id));
            selectOption.splice(itemIndex, 1, tempObj);
        }
    }


    const selectionOptions = [{
        sectionType: 'column', id: 1, border: true,
        sectionContent: [{ id: 1, type: 'radio', label: 'mainCatgory', options: [{ id: 1, label: 'Recommend' }, { id: 2, label: 'Round Trip' }] }]
    },
    {
        sectionType: 'row', id: 2, border: false, sectionContent: [
            { id: 1, type: 'dates', label: '', options: [{ id: 1, label: 'Date Range' }] },
            {
                id: 2, type: 'dropdownHC', label: 'Guests', options: [{
                    id: 1, label: '', totalMax: 45,
                    dropDownOptions: [{ id: 'AD', typeOfInd: 'Adult', subLabel: 'Ages 13 or above', maxValue: 36 },
                    { id: 'CHLD', typeOfInd: 'Children', maxValue: 9, subLabel: 'Ages 2-12' },
                    { id: 'INF', typeOfInd: 'Infants', maxValue: 5, subLabel: 'Under 2' },
                    { id: 'Pet', typeOfInd: 'Pets', maxValue: 5, subLabel: 'Bringing a service animal?' }]
                }]
            }]
    },
    {
        sectionType: 'column', id: 3, border: false, sectionContent: [
            {
                id: 3, type: 'buttons', label: 'Type of travel', options: [{ id: 1, label: 'One day trip' },
                { id: 2, label: 'Two day trip' },
                { id: 3, label: 'Trekking' },
                { id: 4, label: 'Cultural' },
                { id: 5, label: 'Beaches' },
                { id: 6, label: 'Mountain' },
                { id: 7, label: 'Romantic' }]
            },
        ]
    },
    {
        sectionType: 'row', id: 4, border: true, sectionContent: [
            {
                id: 4, type: 'buttons', label: 'Type of traveller', options: [{ id: 1, label: 'Biker' },
                { id: 2, label: 'Car' },
                { id: 3, label: 'Family' },
                { id: 4, label: 'Romantic' },
                ]
            }]
    }]
    return (
        <div className="section__Container">
            {
                selectionOptions.map(section => {
                    return (
                        <section key={section.id} className={`${section.sectionType} section ${section.border ? 'border' : ''}`}>
                            {section.sectionContent.map(selection => {
                                switch (selection.type) {
                                    //case Radio
                                    case 'radio': {
                                        return (
                                            <div key={selection.id} className="typeOfFilter row">
                                                {selection.options.map(option => {
                                                    return (
                                                        <div className='radio' key={option.id}>
                                                            <input type="radio" value={option.id} name={option.label} id={selection.label} onChange={(event) => { onSelect(event) }} />
                                                            <label>{option.label}</label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    }
                                    case 'dates': {
                                        return (
                                            // <div key={selection.id} className="typeOfFilter row">
                                            //     {selection.options.map(option => {
                                            //         return (
                                            //             <div className='date' key={option.id}>
                                            //                 <label>{option.label}</label>
                                            //                 <input type="date" value="" name={option.label} placeholder="Start Date -> End Date" onChange={(event) => { onSelect(event) }} />
                                            //             </div>
                                            //         )
                                            //     })}
                                            <>
                                                <DateRange />
                                            </>
                                        )
                                    }
                                    case 'buttons': {
                                        return (
                                            <div key={selection.id} className="typeOfFilter column">
                                                <label className="selection_label">{selection.label}</label>
                                                <div className="button_arrange">
                                                    {selection.options.map(option => {
                                                        return (
                                                            <FilterButton key={option.id} option={option} selection={selection} buttonClick={buttonClick} />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    }
                                    case 'dropdownHC': {
                                        return (
                                            <div key={selection.id} className="typeOfFilter column">
                                                <label className="dropdown_label">{selection.label}</label>
                                                <HeadCount options={selection.options} countChange={countChange} position={{ top: '12px', left: '187px' }} />
                                            </div>
                                        )
                                    }
                                }
                            })}

                        </section>
                    )
                })
            }
            <div className="search__Submit">
                <button className="skip__route" onClick={() => { getRoutes('skip') }}>Skip & get the route</button>
                <button className="submit__button" onClick={() => { getRoutes('routePlan') }}>Submit</button>
            </div>
        </div>
    );
};

export default SearchDialog;
