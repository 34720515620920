/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        currentUser: '',
        isloading: false,
        error: ''
    },
    reducers: {
        checkCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        SignInStart: (state, action) => {
            state.isloading = true;
        },
        SignInSuccess: (state, action) => {
            state.isloading = false;
            state.currentUser = action.payload
        },
        SignInFailure: (state, action) => {
            state.isloading = false;
            state.error = action.payload
        },
        emailSignUpStart: (state, action) => {
            state.isloading = true;
        },
        emailSignUpSuccess: (state, action) => {
            state.isloading = false;
        },
        emailSignUpFailure: (state, action) => {
            state.isloading = false;
            state.error = action.payload
        }
    }
}
);


// Action creators are generated for each case reducer function
export const { checkCurrentUser, emailSignUpStart, emailSignUpSuccess, emailSignUpFailure, SignInStart, SignInSuccess, SignInFailure } = userSlice.actions;

export default userSlice.reducer;
