import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MapComponent from '../../../CommonComponents/mapcomponent/map_component';
import RadioButton from '../../../CommonComponents/radiobutton/radiobutton';
import CustomRoute from './customroute/customroute';
import './routes.scss';

const RoutesComponent = () => {
    const checkSetter = (value: any) => {
        console.log(value.target.value);
        setHighlightRoute(+value.target.value)
    }
    const onRouteClick = (routeNumber: any) => {
        setSelectedRoute(routeNumber);
        setExpand(true)
    }
    const closeExpand = () => {
        setExpand(false);
        console.log(expandRoute)
    }
    const [highLightRoute, setHighlightRoute]: any = useState(0)
    const [selectedRoute, setSelectedRoute]: any = useState('')
    const [expandRoute, setExpand]: any = useState(false)
    const routes = useSelector((state: any) => state.gmap.routes)
    const routePlan = useSelector((state: any) => state.pat.routePlan)
    return (
        <div className="routes__container">
            {routes && routes.map((route: any, index: number) => {
                return (
                    <div key={index} className={`${index === highLightRoute ? 'highLight' : ''} route__item`} >
                        <div >
                            <RadioButton value={index} reqClass='radio' checkSetter={checkSetter} />
                            <div className="route__Path">
                                {routePlan.map((routePath: any, indexRoute: number) => {
                                    return (<p key={indexRoute}>{routePath}-</p>)
                                })}<p>via {route.routes[0].summary}</p>
                            </div>
                            <div className="route_map_container" onClick={() => onRouteClick(index)}> {<MapComponent zoomControl={false} route={route} />}</div>
                            <div className="route__info">
                                <div className='route__info__details'>  {route.routes[0].legs[0].distance.text} | {route.routes[0].legs[0].duration.text}</div>
                                <div className='route__iternaries__icon'></div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {routes && <CustomRoute defaultRoute={routes[0]} />}
            {expandRoute && <div className="route_map_container__expaned">
                <div className='expandCloseButton' ><button onClick={closeExpand}>X</button></div>
                <div className='mapContainer'><MapComponent zoomControl={true} size={{ width: '98%', height: '90%', position: 'absolute', borderRadius: '10px' }} route={routes[selectedRoute]} /></div>
            </div>}

        </div>
    )
}

export default RoutesComponent;