/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        payment: '',
        paymentStatus: '',
    },
    reducers: {
        setPaymentDetails: (state, action) => {
            state.payment = action.payload.items;
            state.paymentStatus = action.payload.status;
        },
    }
});


// Action creators are generated for each case reducer function
export const { setPaymentDetails } = paymentSlice.actions;

export default paymentSlice.reducer;
